import {useEffect, useState, useRef, Children, isValidElement, cloneElement} from 'react';

import $ from 'jquery'; 

import './assets/css/main.css';
import './assets/js/browser.min.js';
import './assets/js/breakpoints.min.js';
import './assets/js/util.js';
import {main} from './assets/js/main.js';

import trasecom_logo from './images/trasecom_logo.png'
import pic1 from './images/colors2.png'
import kot from './images/kot.jpeg'

import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom"

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {Helmet} from "react-helmet";
import { createCustomEqual } from "fast-equals";



function Intro() {
  return (
    <div id="intro">
    <h1>Trasecom</h1>
      <p>proofing cyfrowy, druk grafiki i fotografii, druk na płótnie i oprawa</p>
      <ul className="actions">
        <li><a href="#header" className="button icon solid solo fa-arrow-down scrolly">Continue</a></li>
      </ul>
    </div>
    );
}

function Header() {
  return (
      <header id="header">
        <Link className="logo" onClick={() => setTab('main')} to='/'><h1>Trasecom</h1></Link>
      </header>
    );
}

function Nav(props) {
  const [tab, setTab] = useState('main');

  return (
    <nav id="nav" ref={props.navRef}>
      <ul className="links">
        <li className={tab === 'main' ? 'active' : ''}><Link onClick={() => setTab('main')} to='/'>O nas</Link></li>
        <li className={tab === 'pricing' ? 'active' : ''}><Link onClick={() => setTab('pricing')} to='/cennik'>Cennik</Link></li>
        <li className={tab === 'order' ? 'active' : ''}><Link onClick={() => setTab('order')} to='/zamowienia'>Zamówienia</Link></li>
      </ul>
    </nav>
  );
}

function Feature(props) {
  return (
    <article>
      <header style={{height: '4em'}}>
        <h2><a href={props.link}>{props.header}</a></h2>
      </header>
      <a href={props.link} className="image fit"><img src={props.image} alt="" /></a>
      <p>{props.content}</p>
      <ul className="actions special">
        <li><Link onClick={() => props.navRef.current.scrollIntoView()} className="button large" to={props.link}>Czytaj więcej</Link></li>
      </ul>
    </article>
  );
}

function Main(props) {
  return (
    <>
      <article className="post featured">
        <header className="major">
          <h2><a href="#">Najwyższej jakości druk pigmentowy</a></h2>
          <p>Wykonujemy druk na 12-kolorowej drukarce EPSON z oprogramowaniem EFI XF do proofingu cyfrowego, druku fotografii i wielkoformatowych form graficznych. Używamy wyłącznie oryginalnych tuszów EPSON UltraChrome HDX i wysokiej jakości papieru. Takie rozwiązanie umożliwia uzyskanie delikatnych przejść tonalnych, głębokiej czerni, stuprocentową powtarzalność, a w połączeniu z odpowiednim papierem - archiwalną trwałość.</p>
        </header>
      </article>

      <section className="posts">
        <Feature navRef={props.navRef} header='Proof cyfrowy z certyfikacją' image={pic1} link='/proof' content='Kontrola kolorów w pracy przekazywanej do drukarni polega na pomiarze próbnej odbitki
kolorowej (proofa) i porównaniu wyniku pomiaru z wzorcem standardu.'/>
        <Feature navRef={props.navRef} header='Druk wielkoformatowy' image={kot} link='/wielkoformatowy' content='Oferujemy druk w najwyższej, fotograficznej jakości na papierach
satynowych (jedwabisty połysk) lub błyszczących o gramaturze 255 g/m2'/>
        {/*<Feature header='Nagłówek' image={pic2} link='#' content='Donec eget ex magna. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque venenatis dolor imperdiet dolor mattis sagittis magna etiam.'/>
        <Feature header='Nagłówek' image={pic2} link='#' content='Donec eget ex magna. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque venenatis dolor imperdiet dolor mattis sagittis magna etiam.'/>*/}
      </section>

      {/*<footer>
        <div className="pagination">
          <a href="#" className="previous">Prev</a>
          <a href="#" className="page active">1</a>
          <a href="#" className="page">2</a>
          <a href="#" className="page">3</a>
          <span className="extra">&hellip;</span>
          <a href="#" className="page">8</a>
          <a href="#" className="page">9</a>
          <a href="#" className="page">10</a>
          <a href="#" className="next">Next</a>
        </div>
      </footer>*/}
      </>
  );
}

function Pricing() {
  return (
    <section className="post">
      <header className="major" style={{margin: "0 0 2rem 0"}}>
        <h1>Cennik</h1>
      </header>
      <h2>Proofy cyfrowe</h2>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Format</th>
              <th>Cena netto</th>
              <th>Wymiary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>B4</td>
              <td>36,- zł</td>
              <td>250×350 mm</td>
            </tr>
            <tr>
              <td>B3</td>
              <td>66,- zł</td>
              <td>350×500 mm</td>
            </tr>
            <tr>
              <td>B2</td>
              <td>121,- zł</td>
              <td>500×700 mm</td>
            </tr>
            <tr>
              <td>B1</td>
              <td>220,- zł</td>
              <td>700×1000 mm</td>
            </tr>
            <tr>
              <td>B0</td>
              <td>410,- zł</td>
              <td>1000×1400 mm</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Druk wielkoformatowy</h2>
      <p>Cena wydruku składa się z sumy ceny druku i ceny podłoża. Ceny netto bez podatku VAT.</p>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Usługa</th>
              <th>Cena za 1 metr<sup>2</sup></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>druk na drukarce wielkoformatowej</td>
              <td>55,- zł</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Podłoże</th>
              <th>Do 3,5 m<sup>2</sup></th>
              <th>3,5 m<sup>2</sup> i więcej</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Papier fotograficzny 190 g/m2 (satyna lub błysk)</td>
              <td>31,- zł</td>
              <td>29,- zł</td>
            </tr>
            <tr>
              <td>Papier niepowlekany 180 g/m2</td>
              <td>20,- zł</td>
              <td>18,- zł</td>
            </tr>
            <tr>
              <td>Folia samoprzylepna biała, matowa</td>
              <td>31,- zł</td>
              <td>29,- zł</td>
            </tr>
            <tr>
              <td>Płótno Canvas Polycotton mat 390 g/m2 </td>
              <td>51,- zł</td>
              <td>48,- zł</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>Minimalna wartość zamówienia druk+podłoże to 40 zł.</p>
      <h2>Naklejanie na płytę piankową</h2>
      <p>Biała, 5 mm płyta piankowa wraz z naklejaniem jednostronnym bez kosztów druku i podłoża. Ceny netto bez podatku VAT.</p> 
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Format</th>
              <th>Cena</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A4/B4</td>
              <td>9,9 zł</td>
            </tr>
            <tr>
              <td>A3/B3</td>
              <td>15,40 zł</td>
            </tr>
            <tr>
              <td>A2/B2</td>
              <td>27,50 zł</td>
            </tr>
            <tr>
              <td>A1/B1</td>
              <td>41,80 zł</td>
            </tr>
            <tr>
              <td>MAX [100×150 cm]</td>
              <td>77 zł</td>
            </tr>
          </tbody>
        </table>
        <p>Minimalna kwota zamówienia wynosi 10,- zł + 23% VAT</p>
      </div>
    </section>
  );
}

function Order() {
  return (
    <section className="post">
      <header className="major">
        <h1>Zamówienia</h1>
      </header>
      <p>Zlecenia proszę wysyłać na adres e-mail trasecom@trasecom.pl. Grafiki o dużej objętości można załączyć w postaci linku do pliku.</p>
    </section>
  );
}

function Proof() {
  return (
    <section className="post">
      <header className="major">
        <h1>Proof cyfrowy z certyfikacją</h1>
        <p>Kontrola kolorów w pracy przekazywanej do drukarni polega na pomiarze próbnej odbitki
kolorowej (proofa) i porównaniu wyniku pomiaru z wzorcem standardu.</p>
      </header>
      <div className="image main"><img src={pic1} alt="" /></div>
      <p>Do symulacji druku na papierach powlekanych proofy w naszej firmie są wykonywane standardowo wg normy Fogra 39 z profilem ISO Coated v.2 Istnieje również możliwość dołączania w ograniczonym zakresie profili wymaganych przez konkretne drukarnie - takie profile należy dołączyć do zamówienia jako oddzielny plik *.icc. Dostępne formaty, w jakich wykonywane są proofy, to B4, B3, B2, B1 i B0. Każdy proof cyfrowy podlega certyfikacji poświadczonej naklejką z wydrukowanymi wartościami pomiaru.</p>
<h2>Kolor CMYK, czy Pantone?</h2>
      <p>W przypadku konieczności wykonania wydruku próbnego z wykorzystaniem kolorów Pantone oferujemy symulację większości kolorów Pantone (wyjątek stanowią kolory metaliczne i fluorescencyjne).</p>
<h2>Podłoże</h2>
      <p>Odbitki próbne wykonujemy na jednym, skalibrowanym podłożu, którym jest papier półmatowy z mikroporową warstwą obrazową przystosowany do proofingu w druku offsetowym.</p>
<h2>Przygotowanie pliku do druku</h2>
      <p>Z uwagi na to, że proof ma obrazować efekt późniejszego druku offsetowego, należy używać tych samych plików, które wykorzystuje drukarnia (najczęściej pliki PDF lub TIFF). Pliki do proofowania nie mogą zawierać dodatkowych profili kolorystycznych. Na życzenie możemy wykonać wycinek proofa, zeskalować lub też połączyć kilka stron na jednym arkuszu. Cena połączonego proofa jest wtedy niższa, ale należy pamiętać o tym, że otrzymuje się tylko jedną certyfikację.</p>
    </section>
  );
}

function Big() {
  return (
    <section className="post">
      <header className="major">
        <h1>Druk wielkoformatowy</h1>
        <p>Oferujemy druk w najwyższej, fotograficznej jakości na papierach
satynowych (jedwabisty połysk) lub błyszczących o gramaturze 255 g/m2.</p>
      </header>
      <div className="image main"><img src={kot} alt="" /></div>
      <p>Papier podawany jest rolki, a maksymalna szerokość zadruku wynosi 110 cm. Zadruki w tej technice wykonywane są z jednej strony. Dla klientów, którzy nie oczekują jakości fotograficznej, oferujemy wydruki na tańszym papierze o gramaturze 170 g/m2. Jest to papier powlekany, sztywniejszy od papieru fotograficznego i jakością zbliżony do papieru offsetowego. Gotowe wydruki mogą być laminowane lub podklejone na różnego rodzaju podłoża.</p>
    </section>
  );
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a, b) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback,
  dependencies
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Map = ({
  children,
  style,
  ...options
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} style={style}/>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

function App() {
  const navRef = useRef(null);

  useEffect(() => {
        document.body.classList.add('is-preload');
        main();
  }, []);

  const render = (status) => {
    return <h1>{status}</h1>;
  };

  const position = {
    lat: 52.205321,
    lng: 20.996867,
  };

  const [zoom, setZoom] = useState(17);
  const [center, setCenter] = useState(position);

  return (
    <div id="wrapper" className="fade-in">
          <Helmet>
            <title>Trasecom</title>
            <meta name="description" content="Proofing cyfrowy, druk grafiki i fotografii, druk na płótnie i oprawa." />
            <meta name="keywords" content="druk, proof, proofing cyfrowy, fotografia, oprawa" />
            <meta property="og:locale" content="pl_PL" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Trasecom" />
            <meta property="og:description" content="Proofing cyfrowy, druk grafiki i fotografii, druk na płótnie i oprawa." />
            <meta property="og:url" content="https://trasecom.pl/" />
            <meta property="og:site_name" content="Trasecom" />
            <link rel="canonical" href="https://trasecom.pl/" />
          </Helmet>

          <Router>
            <Intro />

            <Header />

            <Nav navRef={navRef} />
            <div id="main">
              <Routes>
                <Route exact path="/" element={<Main navRef={navRef} />} /> 
                <Route exact path="/cennik" element={<Pricing />} />
                <Route exact path="/zamowienia" element={<Order />} />
                <Route exact path="/proof" element={<Proof />} />
                <Route exact path="/wielkoformatowy" element={<Big />} />
              </Routes>
            </div>
          </Router>


          <footer id="footer">
            <section>
              <Wrapper apiKey={"AIzaSyDbhTkzh64K8Ng8gpwI632dEo6UxxpTgsY"} render={render}>
                <Map 
                  center={center}
                  zoom={zoom}
                  style={{ flexGrow: "1", height: "100%" }}> 
                    <Marker position={position} />
                </Map>
              </Wrapper>
            </section>
            <section className="split contact">
              <section className="alt">
                <h3>Adres</h3>
                <p>ul. Biały Kamień 2<br />
                02-593 Warszawa</p>
              </section>
              <section>
                <h3>Telefon</h3>
                <p>+48 601291974</p>
              </section>
              <section>
                <h3>Email</h3>
                <p><a href="mailto:trasecom@trasecom.pl">trasecom@trasecom.pl</a></p>
              </section>
              {/*<section>
                <h3>Social</h3>
                <ul className="icons alt">
                  <li><a href="#" className="icon brands alt fa-facebook-f"><span className="label">Facebook</span></a></li>
                  <li><a href="#" className="icon brands alt fa-instagram"><span className="label">Instagram</span></a></li>
                </ul>
              </section>*/}
            </section>
          </footer>

      </div>
  );
}

export default App;
